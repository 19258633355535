import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React from 'react'
import Icon from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/FontAwesome5';
const rocketIcon = <Icon name="rocket" size={30} color="#900" />;

const CardMenu = (props) => {
  return (
    <View style={styles.container}>
      { props.useIcon == 'icon2' ? 
        <Icon2 name={props.iconName} size={40} color="#9370db" />
        :
        <Icon name={props.iconName} size={40} color="#9370db" />
      }
      <View style={{paddingTop: 5,}}>
        <Text>{props.title}</Text>
      </View>
    </View>
  )
}

export default CardMenu

const styles = StyleSheet.create({
    container: {
        flexBasis: 75,
        alignItems:"center",
        // borderWidth: 1,
        borderColor: 'black',
        height: 88,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 10,
    }
})