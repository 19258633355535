import { Button, SafeAreaView, ScrollView, StyleSheet, Text, View, FlatList, TouchableOpacity  } from 'react-native'
import React, { useEffect, useState } from 'react'
import FooterVersion from '../components/FooterVersion'
import { MAPI, primaryColor } from '../components/Constant';
import { getDayName, getLocationName } from '../components/GlobalFunction';


import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SayaLoader from '../components/SayaLoader';

const Item = ({ item, onPress, backgroundColor, textColor, textColorLate }) => (
  <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
    <Text style={[styles.title, styles.textDisplayName, textColor ]}>{item.number}</Text>
    <Text style={[styles.title, styles.textDisplayName, textColor ]}>{item.name}</Text>
    <Text style={[styles.title, styles.textDisplayName, textColor ]}>{item.stage_id[1]}</Text>
    <Text style={[styles.title, styles.textDisplayName, textColor ]}>{item.create_date}</Text>
  </TouchableOpacity>
);

const MyTicketListScreen = ({route, navigation}) => {
    const [ loader, setLoader ] = useState(true);
    const [ selectedId, setSelectedId ] = useState(null);
    const [ myticket_data, setmyticket_data ] = useState([]);
    const [ login, setLogin ] = useState('');
    const [ password, setPassword ] = useState('');

    

    const _retrieveData = async () => {
        try {
          const value = await AsyncStorage.getItem("@login");
          const loginx = await AsyncStorage.getItem("@login");
          const passwordx = await AsyncStorage.getItem("@passwordx");
          
          if (value !== null) {
            // We have data!!
            setLogin(loginx);
            setPassword(passwordx);
          }
        } catch (error) {
          // Error retrieving data
        }
      };

    const myTicket =  () => {
            setLoader(true)
            axios({
            method: 'post',
            url: MAPI+'/myticket',
            headers: {
                "Content-Type": "application/json", 

            },
            data: {
                email: route.params.login,
                password: route.params.password,
            }
            })
            .then(function (response) {
            setLoader(false);

            if (response.data.code === "200") {
                setmyticket_data(response.data.data)
                console.log(response.data.data);

            } else {
                alert(response.data.message)
            }
            
            })
            .catch(function (error) {
            setLoader(false);
            alert("Error fetch")
            console.log(error);
            });
    }


    const renderItem = ({ item }) => {
        const backgroundColor = primaryColor;
        const color = item.id === selectedId ? primaryColor : (item.stage_id[1] == 'Done' ? 'green' : 'red');
        const colorLate =  'red';
        return (
            <View style={styles.containerItem}>
                <Item
                  item={item}
                  onPress={() => {
                      setSelectedId(item.id); console.log(item.id)
                  }}
                //   backgroundColor={{ backgroundColor }}
                  textColor={{ color }}
                  textColorLate={{ colorLate }}
                />
            </View>
        );
    };

    useEffect(() => {
        myTicket()

        return () => {

        }
    }, [])

  if (loader) {
    return (
      <SayaLoader />
    )
  }
    
  return (
    <SafeAreaView style={styles.container}>
        <FlatList
            data={myticket_data}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            extraData={selectedId}
        />
        <FooterVersion />
    </SafeAreaView>
  )
}

export default MyTicketListScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // paddingTop: StatusBar.currentHeight,
    },
    containerItem: {
        padding: 10,
        margin: 10,
        paddingTop: 10,
        borderWidth: 1,
        borderColor: primaryColor,
        borderRadius: 10,
        backgroundColor: "#fff"
    },
      scrollView: {
        // backgroundColor: 'pink',
        flex: 1,
        marginHorizontal: 0,
      },
      text: {
        fontSize: 42,
      },
    
      buttonsView: {
        flex: 1,
        alignItems: "center"
      },
    
      buttonView: {
        marginTop: 20,
        marginBottom: 20,
        width: '50%',
        backgroundColor: "#9370db",
        borderRadius: 30,
        overflow: "hidden",
      },

      textDisplayName: {
        marginBottom: 10,
      }
})