import { SafeAreaView, ScrollView, StyleSheet, Text, View, StatusBar, FlatList } from 'react-native'
import React, { useEffect, useState } from 'react'
import { MAPI, primaryColor } from '../components/Constant';
import FooterVersion from '../components/FooterVersion'
import CardNews from '../components/CardNews'
import axios from 'axios';
import SayaLoader from '../components/SayaLoader';
import { REACT_APP_BASE_API } from "@env"

const NewsScreens = ({route, navigation}) => {
  const [ loader, setLoader ] = useState(true);
  const [selectedId, setSelectedId ] = useState('');
  const [ news_data, setnews_data ] = useState([]);

  const getNews =  () => {
      setLoader(true)
      axios({
      method: 'post',
      url: MAPI +'/news',
      headers: {
          "Content-Type": "application/json", 

      },
      data: {
          email: route.params.login,
          password: route.params.password,
      }
      })
      .then(function (response) {
      setLoader(false);

      if (response.data.code === "200") {
          setnews_data(response.data.data)

      } else {
          alert(response.data.message)
      }
      
      })
      .catch(function (error) {
      setLoader(false);
      alert("Error fetch")
      console.log(error);
      });
  }

  const renderItem = ({ item }) => {
      const backgroundColor = primaryColor;
      const color = item.id === selectedId ? primaryColor : 'black';
      const colorLate =  'red';
      return (
        <CardNews 
          id={item.id}
          title={item.announcement_reason}
          caption={item.announcement}
          create_date={item.create_date}  
        />
      );
  };

  useEffect(() => {
    console.log(route)
    getNews()
  
    return () => {
      
    }
  }, [])

  if (loader) {
    return (
      <SayaLoader />
    )
  }
  
  return (
    <SafeAreaView style={styles.container}>
      <FlatList 
        data={news_data}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        extraData={selectedId}
      />
        <FooterVersion />
    </SafeAreaView>
  )
}

export default NewsScreens

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    // backgroundColor: 'pink',
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
})