import { Platform, Image, StyleSheet, Text, View, ScrollView, SafeAreaView, TouchableOpacity, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import CardMenu from '../components/CardMenu';
import FooterVersion from '../components/FooterVersion';
import { AuthContext } from '../components/Context';

import Icon from 'react-native-vector-icons/FontAwesome';
import AsyncStorage, { useAsyncStorage } from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import SayaLoader from '../components/SayaLoader';
import { globalStyles } from '../components/Constant';


const rocketIcon = <Icon name="rocket" size={30} color="#900" />;

const HomepageScreen = ({ route, navigation  }) => {
    const [ loader, setLoader ] = useState(true);
    const [name, setName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [login, setLogin] = useState([]);
    const [password, setPassword] = useState([]);
    const [authtodo, setAuthtodo] = useState([]);
    const [location, setLocation] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationDisplayName, setLocationDisplayName] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const { signOut, dor } = React.useContext(AuthContext);

    const _storeData = async (key,value) => {
        try {
          await AsyncStorage.setItem(
            key,
            value
          );
        } catch (error) {
          // Error saving data
        }
      };
    
    const _retrieveData = async (key) => {
        try {
            const value = await AsyncStorage.getItem(key);

            if (value !== null) {
                // We have data!!
                if (key === "@name") {
                    setName(dor(value));
                }

                if (key === "@company_name") {
                    setCompanyName(dor(value));
                }

                if (key === "@login") {
                    setLogin(dor(value));
                }
                
                if (key === "@passwordx") {
                    setPassword(dor(value));
                }

                if (key === "@authtodo") {
                    var obj_val = JSON.parse(value)
                    setAuthtodo(obj_val.name);
                }
            }
        } catch (error) {
            // Error retrieving data
        }
    };

    _retrieveData("@name")
    _retrieveData("@company_name")
    _retrieveData("@login")
    _retrieveData("@passwordx")
    _retrieveData("@authtodo")

    useEffect(() => {
        _retrieveData("@name")
        _retrieveData("@company_name")
        _retrieveData("@login")
        _retrieveData("@passwordx")
        _retrieveData("@authtodo")

      return () => {

        }
    }, [
        // alert("dir")
            (latitude && longitude ? getLocationName(latitude, longitude) : 'None Get Location Name')
    ])

    // HANDLERS
    const handleProfile = (params) => {
        navigation.navigate("Profile", {
            login: login,
            password: password,
            name: name,
            companyName: companyName,
        })
    }

    const handleAttendance = (params) => {
        navigation.navigate("Attendance", {
            login: login,
            password: password,
        })
    }

    const handleDiscuss = (params) => {
        navigation.navigate("Discuss")
    }

    const handleNews = (params) => {
        navigation.navigate("News", {
            login: login,
            password: password,
        })
    }

    const handleSignOut = (params) => {
        signOut();
        console.log("sign out pressed")
    }

    useEffect( () => {
        setTimeout( () => {
            setLoader(false);
        }, 1000)
    }, [])

    if (loader) {
        return (
            <SayaLoader />
        )
    }
    
  return (
    <View style={globalStyles.osContainer}>
        <View style={styles.container}>
            <View style={styles.jumbotronView}>
                <View style={{flex: 1, alignItems:'center', marginTop: 15,}}>
                    <Image
                        style={styles.profileImgHeader}
                        source={{
                        uri: 'https://randomuser.me/api/portraits/men/33.jpg',
                        }}
                    />
                    <Text style={styles.textName}>{name}</Text>
                    <Text style={styles.textCompany}>{login}</Text>
                    <Text style={styles.textCompany}>{companyName}</Text>
                </View>
            </View>

            <View style={{ marginTop: 20}}>
                <Text>{locationDisplayName}</Text>
            </View>

            <SafeAreaView style={styles.containerx}>
                <ScrollView>
                    <View style={styles.listMenuView}>
                        <TouchableOpacity onPress={() =>  handleAttendance() }>
                                <CardMenu title="Attendance" iconName="check" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>  handleProfile() }>
                            <CardMenu title="Profile" iconName="user" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>  handleNews() }>
                            <CardMenu title="News" iconName="newspaper-o" />
                        </TouchableOpacity>
                        {/* <TouchableOpacity onPress={() =>  handleDiscuss() }>
                            <CardMenu title="Discuss" iconName="wechat" />
                        </TouchableOpacity> */}
                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("MyTicketList", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="My Ticket List" iconName="sticky-note" />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("MyTask", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="My Task" iconName="tasks" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("MyFSM", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="My FSM" iconName="truck" />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("Employee", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="Employee" iconName="users" />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("Warehouse", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="Warehouse" useIcon="icon2" iconName="warehouse" />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("Purchasing", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="Purchasing" iconName="cart-plus" />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>  
                            navigation.navigate("Services", {
                                login: login,
                                password: password,
                            })
                        }>
                            <CardMenu title="Services" useIcon="icon2" iconName="hands-helping" />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>  handleSignOut() }>
                            <CardMenu title="Logout" iconName="sign-out" />
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </SafeAreaView>

            <FooterVersion />
        </View>
    </View>
  )
}

export default HomepageScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    jumbotronView: {
        marginTop: 25,
        width: "95%",
        height: 235,
        backgroundColor: '#9370db',
        borderRadius: 30,
    },
    profileImgHeader: {
        width: 120,
        height: 120,
        borderRadius: 60,
    },
    textName: {
        marginTop: 10,
        color: "#fff",
        fontSize: 16,
        fontWeight: 'bold',
    },
    textCompany: {
        marginTop: 10,
        color: "#fff",
        fontSize: 12,
    },


    listMenuView: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        width: "95%",
        borderRadius: 30,
        marginLeft: 10,
        // backgroundColor: '#9370db',
        justifyContent: "center"
    },


    containerx: {
        flex: 7,
    },
      scrollView: {
        // backgroundColor: 'pink',
        // marginHorizontal: 20,
      },
      text: {
        fontSize: 42,
      },
})