import { Button, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, View, TouchableOpacity , Image} from 'react-native'
import React, { useEffect, useState } from 'react'
import { globalStyles, MAPI, primaryColor  } from '../components/Constant' 
import SayaLoader from '../components/SayaLoader';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';

const ProfileEditScreen = ({route, navigation}) => {
    const { name } = route.params;
    const [loader, setLoader] = useState(true);

    const [ currentImageUser, setCurrentImageUser ] = useState(route.params.image_256)
    const [ selectedImage, setSelectedImage] = useState("");
    const [nameEdit, setNameEdit] = useState(name);
    const [workMobile, setWorkMobile] = useState(route.params.WorkMobile);
    const [MobilePhone, setMobilePhone] = useState(route.params.MobilePhone);
    const [workPhone, setWorkPhone] = useState(route.params.WorkPhone);
    const [workEmail, setWorkEmail] = useState(route.params.WorkEmail);
    const [workAddress, setWorkAddress] = useState([]);
    const [Address, setAddress] = useState([]);
    const [Nationality, setNationality] = useState([]);
    const [Gender, setGender] = useState(route.params.Gender);
    const [Dob, setDob] = useState(route.params.Birthday);
    const [PlaceOfBirth, setPlaceOfBirth] = useState(route.params.PlaceOfBirth);
    const [CountryOfBirth, setCountryOfBirth] = useState(route.params.CountryOfBirth[1]);
    const [IdentificationNo, setIdentificationNo] = useState([]);
    const [MaritalStatus, setMaritalStatus] = useState(route.params.Marital);
    const [NumberOfChildren, setNumberOfChildren] = useState([]);
    const [EmergencyContact, setEmergencyContact] = useState(route.params.EmergencyContact);
    const [EmergencyPhone, setEmergencyPhone] = useState(route.params.EmergencyPhone);
    const [CertificateLevel, setCertificateLevel] = useState([]);
    const [Other, setOther] = useState([]);
    const [FieldOfStudy, setFieldOfStudy]  = useState(route.params.FieldOfStudy);
    const [School, setSchool]     = useState(route.params.School);

    let openImagePickerAsync = async () => {
        let pickerResult = await ImagePicker.launchImageLibraryAsync();
        if (pickerResult.cancelled === true) {
            return;
        }
        console.log(pickerResult)
        setSelectedImage({ localUri: pickerResult.uri });
    }


    // INIT
    useEffect(() => {
        setTimeout(() => {
            setLoader(false); 
        }, 1000);
    
        return () => {
        }
    }, [])
    

    const editMyProfile = () => {
        console.log("edit")
        setLoader(true);

        axios({
          method: 'post',
          url: MAPI+'/updatemyemployee',
          headers: {
            "Content-Type": "application/json", 
          },
          data: {
            email: route.params.login,
            password: route.params.password,
            emergency_phone: EmergencyPhone,
            emergency_contact: EmergencyContact,
            study_field: FieldOfStudy,
            study_school: School,
            mobile_phone: workPhone,
          }
        })
        .then(function (response) {
          if (response.data.code == "200") {
                setLoader(false)
                navigation.goBack()
          } else {
            setLoader(false)
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          alert("Error fetch")
          setLoader(false)
          console.log(error);
        });
    }

    if ( loader ) {
        return (
            <SayaLoader />
        )
    }

  return (
    <View style={globalStyles.osContainer}>
        <View style={styles.baseContainer}>
            <SafeAreaView style={styles.safeAreaView}>
                <ScrollView style={styles.scrollView}>
                    <View style={styles.imageUploadView}>
                        <Image
                            source={{ uri: (currentImageUser && selectedImage.localUri? selectedImage.localUri : currentImageUser) }}
                        style={styles.thumbnail}
                            />
                        <Text style={{ fontSize: 10, marginTop: 10,}}>Change your photo click the button below</Text>
                        <TouchableOpacity 
                            onPress={ openImagePickerAsync }
                            style={styles.buttonUploadView}
                        >
                            <Text style={styles.buttonUploadText}>Pick a photo</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{borderWidth: 1, margin: 10, padding: 5, borderRadius: 7, borderColor:primaryColor}}>
                        <Text style={{ fontSize: 12,}}>Work Information</Text>
                        <View style={styles.formGroup}>
                            <Text>Mobile Phone</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Mobile Phone"
                                defaultValue={MobilePhone}
                                onChangeText={ (MobilePhone) => setMobilePhone(MobilePhone)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Work Mobile</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Work Mobile"
                                defaultValue={workMobile}
                                onChangeText={ (workMobile) => setWorkMobile(workMobile)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Work Phone</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Work Phone"
                                defaultValue={workPhone}
                                onChangeText={ (workPhone) => setWorkPhone(workPhone)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Work Email</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Work Email"
                                defaultValue={workEmail}
                                onChangeText={ (workEmail) => setWorkEmail(workEmail)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Work Address</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Work Address"
                                defaultValue={workAddress}
                                onChangeText={ (workAddress) => setWorkAddress(workAddress)}
                            />
                        </View>
                    </View>

                    <View style={{borderWidth: 1, margin: 10, padding: 5, borderRadius: 7, borderColor:primaryColor}}>
                        <Text style={{ fontSize: 12,}}>Citizenship</Text>
                        <View style={styles.formGroup}>
                            <Text>Address</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Address"
                                defaultValue={Address}
                                onChangeText={ (Address) => setAddress(Address)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Nationality (Country) </Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Nationality (Country) "
                                defaultValue={Nationality}
                                onChangeText={ (Nationality) => setNationality(Nationality)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Gender</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Gender"
                                defaultValue={Gender}
                                onChangeText={ (Gender) => setGender(Gender)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Date Of Birth</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Date Of Birth"
                                defaultValue={workAddress}
                                onChangeText={ (workAddress) => setWorkAddress(workAddress)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Place Of Birth</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Place Of Birth"
                                defaultValue={PlaceOfBirth}
                                onChangeText={ (PlaceOfBirth) => setPlaceOfBirth(PlaceOfBirth)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Country Of Birth</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Country Of Birth"
                                defaultValue={CountryOfBirth}
                                onChangeText={ (CountryOfBirth) => setCountryOfBirth(CountryOfBirth)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Identification No</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Identification No"
                                defaultValue={IdentificationNo}
                                onChangeText={ (IdentificationNo) => setIdentificationNo(IdentificationNo)}
                            />
                        </View>
                    </View>
                    
                    <View style={{borderWidth: 1, margin: 10, padding: 5, borderRadius: 7, borderColor:primaryColor}}>
                        <Text style={{ fontSize: 12,}}>Family</Text>
                        <View style={styles.formGroup}>
                            <Text>Marital Status</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Marital Status"
                                defaultValue={MaritalStatus}
                                onChangeText={ (MaritalStatus) => setMaritalStatus(MaritalStatus)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>NumberOfChildren </Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="NumberOfChildren "
                                defaultValue={NumberOfChildren}
                                onChangeText={ (NumberOfChildren) => setNumberOfChildren(NumberOfChildren)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Emergency Contact</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Emergency Contact"
                                defaultValue={EmergencyContact}
                                onChangeText={ (EmergencyContact) => setEmergencyContact(EmergencyContact)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Emergency Phone</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Emergency Phone"
                                defaultValue={EmergencyPhone}
                                onChangeText={ (EmergencyPhone) => setEmergencyPhone(EmergencyPhone)}
                            />
                        </View>
                    </View>

                    <View style={{borderWidth: 1, margin: 10, padding: 5, borderRadius: 7, borderColor:primaryColor}}>
                        <Text style={{ fontSize: 12,}}>Education</Text>
                        <View style={styles.formGroup}>
                            <Text>Certificate Level</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Certificate Level"
                                defaultValue={CertificateLevel}
                                onChangeText={ (CertificateLevel) => setCertificateLevel(CertificateLevel)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>NumberOfChildren </Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="NumberOfChildren "
                                defaultValue={NumberOfChildren}
                                onChangeText={ (NumberOfChildren) => setNumberOfChildren(NumberOfChildren)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Other</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Other"
                                defaultValue={Other}
                                onChangeText={ (Other) => setOther(Other)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>Field Of Study</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="Field Of Study"
                                defaultValue={FieldOfStudy}
                                onChangeText={ (FieldOfStudy) => setFieldOfStudy(FieldOfStudy)}
                            />
                        </View>
                        <View style={styles.formGroup}>
                            <Text>School</Text>
                            <TextInput 
                                style={styles.formControl} 
                                placeholder="School"
                                defaultValue={School}
                                onChangeText={ (School) => setSchool(School)}
                            />
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
            <View style={styles.containerRow}>
                <View style={styles.buttonView}>
                    <Button title='Cancel' color={"grey"} onPress={() =>  navigation.goBack()} />
                </View>
                <View style={styles.buttonView}>
                    <Button title='Save' color={primaryColor} onPress={() => editMyProfile() } />
                </View>
            </View>
        </View>
    </View>
  )
}

export default ProfileEditScreen

const styles = StyleSheet.create({
    baseContainer: {
        flex: 1,
        flexDirection: "column",
        padding: 0,
    },
    safeAreaView: {
        flex: 1,
        // paddingTop: StatusBar.currentHeight,
      },
    scrollView: {
    // backgroundColor: 'pink',
    marginHorizontal: 0,
    },
    formGroup: {
        padding: 10,
    },
    formControl: {
        backgroundColor: "#fff",
        marginTop: 5,
        padding: 5,
        height: 45,
        borderRadius: 10,
    },
    containerRow: {
        // flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
        marginBottom: 20,
      },
    containerCol: {
    flexBasis: 100,
    margin: 5,
    },
    buttonView: {
        marginTop: 20,
        width: '45%',
        borderRadius: 30,
        overflow: "hidden",
    },

    imageUploadView : {
        alignItems: "center",
        marginTop: 25,
        marginBottom: 25,
    },  
    buttonUploadView: {
        backgroundColor: primaryColor,
        width: "45%",
        borderRadius: 30,
        marginTop: 10,
        padding: 10,
    },
    buttonUploadText: {
        color: "white",
        textAlign: "center"
    },
    thumbnail: {
        width: 120,
        height: 120,
        resizeMode: "contain",
        borderRadius: 70,
    }

})