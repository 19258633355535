import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import React from 'react'

const SayaLoader = () => {
  return (
    <View style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color="#9370db" />
    </View>
  )
}

export default SayaLoader

const styles = StyleSheet.create({})