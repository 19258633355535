import { SafeAreaView, ScrollView, StyleSheet, Text, View, StatusBar, Button, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import FooterVersion from '../components/FooterVersion'

import axios from 'axios'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import * as Location from "expo-location";
import { MAPI } from '../components/Constant'

const AttendanceScreen = ({ route, navigation }) => {
  const [ loader, setLoader ] = useState(false);
  const { login, password } = route.params;
  const [ attendance_state, setattendance_state] = useState([]);
  const [location, setLocation] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationDisplayName, setLocationDisplayName] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);

  const [checkedMocked, setCheckedMocked] = useState(null);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location);
      


    })();
  }, []);


  let text = 'Waiting..';
  if (errorMsg) {
    text = errorMsg;
  } else if (location) {
    text = (location);
    var lat = text.coords.latitude
    var lon = text.coords.longitude
    var mocked = text.mocked
    // console.log(mocked)
  }

  // INIT 
  useEffect(() => {
    // while ( locationDisplayName == null) {
    //   console.log("fuck");
    // }

    ( async () => {
          let { status } = await Location.requestForegroundPermissionsAsync();
  
          if (status !== 'granted') {
              setErrorMsg('Permission to access location was denied');
              return;
          }
      
          let location = await Location.getCurrentPositionAsync({});
          setLocation(location);

          let text = 'Waiting..';
          if (errorMsg) {
              text = errorMsg;
          } else if (location) {
              text = (location);
              setLatitude(text.coords.latitude)
              setLongitude(text.coords.longitude)
              var mocked = text.mocked
              console.log("check mocked 1")
              console.log(mocked);
              setCheckedMocked(mocked);
              if (mocked) {
                alert("gps f detected #1");
              }

          }

    } )();

    attncheck();

    return () => {
        setLoader(false)
    }
    
  }, [])
  
  // START HANDLERS
  const attncheck = () => {
    setLoader(true)
    axios({
      method: 'post',
      url: 'http://mapi.fibermedia.co.id:21491/attncheck',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: login,
        password: password,
        latitude: latitude,
        longitude: longitude,
      }
    })
    .then(function (response) {
      setLoader(false)

      if (response.data.code == "200") {
        setattendance_state(response.data.data[0].attendance_state)
        
      } else {
        alert(response.data.message)
      }
    })
    .catch(function (error) {
      alert("Error attn check")
      setLoader(false)
      console.log(error);
    });
  }
  
  
  var config = {
    method: 'get',
    url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`,
    headers: { }
  };

  axios(config)
  .then(function (response) {
      setLocationDisplayName(response.data.display_name)
      console.log("check mocked 2")
      console.log(mocked);
      console.log(response.data.display_name)
      setCheckedMocked(mocked);
      if (mocked) {
        alert("gps f detected #2");
      }
  })
  .catch(function (error) {
      alert("Check Security Location Data")
      setLoader(false)
      console.log(error);
      var mocked = text.mocked
  });

  
  const handleCheckIn = () => {
    setLoader(true)

    if (attendance_state === "checked_in") {
      alert("You has been checked in");
      setLoader(false)
      return false;
    }

    if (checkedMocked) {
      alert("Please Turn Off Your F GPS") 
      setLoader(false);
      return false;
    }

    axios({
      method: 'post',
      url: MAPI+'/checkin',
      headers: {
        "Content-Type": "application/json", 
      },
      data: {
        email: login,
        password: password,
        x_ci_location: locationDisplayName,
        check_in_latitude: lat,
        check_in_longitude: lon,

      }
    })
    .then(function (response) {
      setLoader(false)

      if (response.data.code == "200") {
          attncheck();
          alert(response.data.message)
      }

    })
    .catch(function (error) {
      alert("Error fetch check in")
      setLoader(false)
      console.log(error);
    });
  }

  const handleCheckOut = () => {
    setLoader(true)

    if (attendance_state === "checked_out") {
      alert("Please check in first");
      setLoader(false)
      return false;
    }

    axios({
      method: 'post',
      url: MAPI+'/checkout',
      headers: {
        "Content-Type": "application/json", 
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      data: {
        email: login,
        password: password,
      }
    })
    .then(function (response) {
      setLoader(false)
      if (response.data.code == "200") {
          attncheck();
          alert(response.data.message)
      }

    })
    .catch(function (error) {
      alert("Error fetch")
      setLoader(false)
      console.log(error);
    });
  }
  // END HANDLERS

  if (loader) {
    return (
      <View style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
        <ActivityIndicator size="large" color="#9370db" />
      </View>
    )
  }

  return (
      
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}>
          <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10,}}>
            <Text>
              Your Current Location
            </Text>
            <Text>
              {locationDisplayName}
            </Text>
          </View>
          <View style={styles.buttonsView}>
            <View  style={styles.buttonView}>
              <Button title='Check In' color={"#9370db"} onPress={ handleCheckIn } />
            </View>
            <View  style={styles.buttonView}>
              <Button  title='Check Out' color={"#9370db"} onPress={ handleCheckOut } />
            </View>
            <View  style={styles.buttonView}>
              <Button  title='Report' color={"#9370db"} onPress={ () => navigation.navigate("AttendanceReport", {
                login: route.params.login,
                password: route.params.password,
              }) } />
            </View>
          </View>
        </ScrollView>
          <FooterVersion />
      </SafeAreaView>

  )
}

export default AttendanceScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    // backgroundColor: 'pink',
    flex: 1,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },

  buttonsView: {
    flex: 1,
    alignItems: "center"
  },

  buttonView: {
    marginTop: 20,
    marginBottom: 20,
    width: '50%',
    backgroundColor: "#9370db",
    borderRadius: 30,
    overflow: "hidden",
  }
})