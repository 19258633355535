import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const ForgotPasswordScreen = () => {
  return (
    <View style={styles.container}>
      <Text>ForgotPasswordScreen</Text>
    </View>
  )
}

export default ForgotPasswordScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        // alignItems: 'center',
        // justifyContent: 'center',
    }
})