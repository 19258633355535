import { StyleSheet, Text, View, SafeAreaView, ScrollView } from 'react-native'
import React from 'react'

const WarehouseStockListScreen = () => {
  return (
    <View>
        <SafeAreaView>
            <ScrollView>
                <Text>WarehouseStockListScreen</Text>
            </ScrollView>
        </SafeAreaView>
    </View>
  )
}

export default WarehouseStockListScreen

const styles = StyleSheet.create({})