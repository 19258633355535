import axios from "axios";

export function getDayName(dateStr, locale) {

    var days2 = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(dateStr.substr(0,10));
    var dayName = days2[d.getDay()];
    return dayName;
}

export   function getLocationName(lat,lon) {
    var config = {
        method: 'get',
        url: 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+lat+'&lon='+lon+'',
        headers: { }
      };

    axios(config)
    .then(function (response) {
        console.log(JSON.stringify(response.data));
        console.log(response.data.display_name)
        return response.data.display_name
        // return "cok";
        // var locName = response.data;
    })
    .catch(function (error) {
        console.log(error);
    });
}