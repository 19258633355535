import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const FooterVersion = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Version 1.0.0</Text>
    </View>
  )
}

export default FooterVersion

const styles = StyleSheet.create({
    container: {
        padding: 10,
        alignItems: "center",
    },  
    text: {
        fontSize: 12,
    }
})