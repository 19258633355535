import { SafeAreaView, ScrollView, StyleSheet, Text, View, StatusBar, FlatList } from 'react-native'
import React, { useEffect, useState } from 'react'
import { primaryColor } from '../components/Constant';
import FooterVersion from '../components/FooterVersion'
import CardServices from '../components/CardServices'
import axios from 'axios';
import SayaLoader from '../components/SayaLoader';

const ServiceScreen = ({route, navigation}) => {
  const [ loader, setLoader ] = useState(true);
  const [selectedId, setSelectedId ] = useState('');
  const [ service_data, setservice_data ] = useState([]);

  const getService =  () => {
      setLoader(true)
      axios({
      method: 'post',
      url: 'http://mapi.fibermedia.co.id:21491/serviceslist',
      headers: {
          "Content-Type": "application/json", 

      },
      data: {
          email: route.params.login,
          password: route.params.password,
      }
      })
      .then(function (response) {
      setLoader(false);

      if (response.data.code === "200") {
          setservice_data(response.data.data)

      } else {
          alert(response.data.message)
      }
      
      })
      .catch(function (error) {
      setLoader(false);
      alert("Error fetch")
      console.log(error);
      });
  }

  const renderItem = ({ item }) => {
      const backgroundColor = primaryColor;
      const color = item.id === selectedId ? primaryColor : 'black';
      const colorLate =  'red';
      return (
        <CardServices 
          id={item.id}
          name={item.x_name}
          description={item.x_description}
          host={item.x_host}
          state={item.x_state}
          rxont={item.x_rxont}
          onlineduration={item.x_onlineduration}
          realhwid={item.x_realhwid}  
        />
      );
  };

  useEffect(() => {
    console.log(route)
    getService()
  
    return () => {
      
    }
  }, [])

  if (loader) {
    return (
      <SayaLoader />
    )
  }
  
  return (
    <SafeAreaView style={styles.container}>
      <FlatList 
        data={service_data}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        extraData={selectedId}
      />
        <FooterVersion />
    </SafeAreaView>
  )
}

export default ServiceScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    // backgroundColor: 'pink',
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
})