import { Platform, Button, StyleSheet, Text, TextInput, View, Alert, ActivityIndicator } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'


import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from '../components/Context';
import FooterVersion from '../components/FooterVersion';
import { REACT_APP_BASE_API } from "@env"
import { MAPI } from '../components/Constant';

export default function HomeScreen({ navigation }) {  
  const [loader, setLoader] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [dor, setDor] = useState('');

  const { signIn, isSignIn } = React.useContext(AuthContext);

  useEffect(() => {
    setTimeout( () => {
      setLoader(false)
    }, 1000)

    console.log("Platform : "+Platform.OS)

    return () => {
     
    }
  }, [])
  

  const _storeData = async (key,value) => {
    try {
      await AsyncStorage.setItem(
        key,
        value
      );
    } catch (error) {
      // Error saving data
    }
  };

  const _retrieveData = async (key) => {
    try {
      const value = await AsyncStorage.getItem(key);
      setDor(value);

      if (value !== null) {
        // We have data!!
        console.log(value);
      }
    } catch (error) {
      // Error retrieving data
    }
  };

  const handleLogin = () => {

    if ( !email ) {
      alert("Please fill email");
      return false;
    }

    if ( !password ) {
      alert("Please fill password");
      return false;
    }

    Alert.alert(
      "Ameria ask",
      "Are you sure want to login?",
      [
        {
          text: "Ask me later",
          onPress: () => console.log("Ask me later pressed")
        },
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        { text: "OK", onPress: () => {
          setLoader(true);
          console.log("Ok Pressed")
          console.log(email)
          console.log(password)
          axios({
            method: 'post',
            url: MAPI+'/odooLogin',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              host: host,
              port: port,
              email: email,
              password: password,
            }
          })
          .then(function (response) {
            setLoader(false)

            if (response.data.code == "200") {
                console.log("login berhasil")
                signIn();

              _storeData('@authtodo', JSON.stringify(response.data.authtodo[0]) );
              _storeData('@name', JSON.stringify(response.data.authtodo[0].name) );
              _storeData('@mobile_phone', JSON.stringify(response.data.authtodo[0].mobile_phone) );
              _storeData('@job_title', JSON.stringify(response.data.authtodo[0].job_title) );
              _storeData('@login', JSON.stringify(response.data.authtodo[0].login) );
              _storeData('@passwordx',  JSON.stringify(password) );
              _storeData('@employee_id', JSON.stringify(response.data.authtodo[0].employee_id) );
              _storeData('@company_id', JSON.stringify(response.data.authtodo[0].company_id[0]) );
              _storeData('@company_name', JSON.stringify(response.data.authtodo[0].company_id[1]) );

              setEmail(null);
              setPassword(null);
              navigation.navigate("Homepage", {
                company_name: response.data.authtodo[0].company_id[1],
                login: response.data.authtodo[0].login,
                password: response.data.authtodo[0].password,
                name: JSON.stringify(response.data.authtodo[0].name)
              })
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {
            // alert(error.data.message)
            // alert("Error fetch")
            setLoader(false)
            console.log(error);
          });
          
        } }
      ]
    )
  }

  const handleLoginWEB = () => {

    if ( !email ) {
      alert("Please fill email");
      return false;
    }

    if ( !password ) {
      alert("Please fill password");
      return false;
    }

    if (confirm("Apa kamu yakin mau login ?")) {
          setLoader(true);
          console.log("Ok Pressed")
          console.log(email)
          console.log(password)
          axios({
            method: 'post',
            url: MAPI+'/odooLogin',
            headers: {
              "Content-Type": "application/json", 
            },
            data: {
              host: host,
              port: port,
              email: email,
              password: password,
            }
          })
          .then(function (response) {
            setLoader(false)

            if (response.data.code == "200") {
                console.log("login berhasil")
                signIn();

              _storeData('@authtodo', JSON.stringify(response.data.authtodo[0]) );
              _storeData('@name', JSON.stringify(response.data.authtodo[0].name) );
              _storeData('@mobile_phone', JSON.stringify(response.data.authtodo[0].mobile_phone) );
              _storeData('@job_title', JSON.stringify(response.data.authtodo[0].job_title) );
              _storeData('@login', JSON.stringify(response.data.authtodo[0].login) );
              _storeData('@passwordx',  JSON.stringify(password) );
              _storeData('@employee_id', JSON.stringify(response.data.authtodo[0].employee_id) );
              _storeData('@company_id', JSON.stringify(response.data.authtodo[0].company_id[0]) );
              _storeData('@company_name', JSON.stringify(response.data.authtodo[0].company_id[1]) );

              setEmail(null);
              setPassword(null);
              navigation.navigate("Homepage", {
                company_name: response.data.authtodo[0].company_id[1],
                login: response.data.authtodo[0].login,
                password: response.data.authtodo[0].password,
                name: JSON.stringify(response.data.authtodo[0].name)
              })
            } else {
              alert(response.data.message)
            }
          })
          .catch(function (error) {
            // alert(error.data.message)
            // alert("Error fetch")
            setLoader(false)
            console.log(error);
          });
    }
  }

  if (loader) {
    return (
      <View style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color="#9370db" />
      </View>
    )
  }

  
  return (
    <View style={styles.osContainer}>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Welcome</Text>
          <Text style={styles.headerCaption}>Sign in now!</Text>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.inputView}>
              <TextInput
                  style={styles.TextInput}
                  placeholder="Masukan Email"
                  placeholderTextColor="#003f5c"
                  onChangeText={(email) => setEmail(email)}
              />
          </View>
          <View style={styles.inputView}>
              <TextInput
                  style={styles.TextInput}
                  placeholder="Masukan Password"
                  placeholderTextColor="#003f5c"
                  secureTextEntry={true}
                  onChangeText={(password) => setPassword(password)}
              />
          </View>
          <View>
              <Text onPress={() => navigation.navigate('ForgotPassword')}> Forgot Password</Text>
          </View>
          <View style={styles.buttonView}>
              <Button title='Sign In' color={"#9370db"} onPress={ (Platform.OS == 'web' ? handleLoginWEB : handleLogin) } />
          </View>
        </View>
          <FooterVersion />
      </View>
    </View>
    
  )
  
}

const styles = StyleSheet.create({
    osContainer: {
      width: (Platform.OS == 'web'? 425 : 'none'),
      flex: 1,
      justifyContent: 'center',
      alignSelf: 'center'
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 16
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    headerContainer: {
      flex: 1,
      justifyContent: "center",
    },
    headerText: {
      fontSize: 46,
      fontWeight: "bold",
      color: "#9370db"
    },
    headerCaption: {
        marginTop: 5,
        fontSize: 26,
        fontWeight: "bold",
        color: "#9370db"
    },
    contentContainer: {
      flex: 2,
      backgroundColor: "#fff",
      borderWidth: 2,
      borderBottomColor: "#fff",
      borderColor: "#9370db",
      justifyContent: "center",
      alignItems: "center",
      borderTopEndRadius: 30,
      borderTopStartRadius: 30,
    },
    inputView: {
      borderWidth: 1,
      borderRadius: 30,
      borderColor: '#9370db',
      width: "70%",
      height: 45,
      marginBottom: 20,
      alignItems: "center"
    },
    TextInput: {
        width: '65%',
        height: 50,
        flex: 1,
        padding: 10,
        marginLeft: 20,
    },
    buttonView: {
      marginTop: 20,
      width: '50%',
      borderRadius: 30,
      overflow: "hidden",
    },
})