import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const CardServices = (props) => {
  const regex = /(<([^>]+)>)/ig;
  //const caption = props.caption.replace(regex, '');
  return (
    <View style={styles.container}>
      <View style={styles.ServicesTitleView}>
        <Text style={styles.ServicesTitleText}>{props.name}</Text>
      </View>
      <View style={styles.ServicesContentView}>
        <Text style={styles.ServicesContentText}>Description:{"\n"}
          {props.description}{"\n"}{"\n"}
          Host:{"\n"}
          {props.host}{"\n"}{"\n"}
          State:{"\n"}
          {props.state}{"\n"}{"\n"}
          RX ONT:{"\n"}
          {props.rxont}{"\n"}{"\n"}
          Online Duration:{"\n"}
          {props.onlineduration}{"\n"}{"\n"}
          Real HW ID: {"\n"}
          {props.realhwid}{"\n"}{"\n"}
          </Text>
      </View>
      <View style={styles.ServicesFooterView}>
        <Text style={styles.ServicesFooterText}>Copyright by Remala Group</Text>
      </View>
    </View>
  )
}





export default CardServices

const styles = StyleSheet.create({
  container: {
      flexBasis: 75,
      borderWidth: 2,
      borderColor: '#9370db',
      borderRadius: 20,
      height: 330,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
  },
  ServicesTitleView: {
    marginBottom: 10,
  },
  ServicesTitleText: {
    height: 20,
    fontSize: 18,
    color: "#9370db"
  },
  ServicesContentView: {
    flex: 1,
    marginBottom: 1,
  },
  ServicesContentText: {
    fontSize: 14,
  },
  ServicesFooterView: {
    flex: 0,
  },
  ServicesFooterText: {
    fontSize: 12,
  }
})