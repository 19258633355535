import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './screens/HomeScreen';
import HomepageScreen from './screens/HomepageScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ProfileScreen from './screens/ProfileScreen';
import AttendanceScreen from './screens/AttendanceScreen';
import NewsScreens from './screens/NewsScreens';
import DiscussScreen from './screens/DiscussScreen';
import ServiceScreen from './screens/ServiceScreens';

import { AuthContext } from './components/Context';
import React, { useEffect, useMemo, useState } from 'react';
import ProfileEditScreen from './screens/ProfileEditScreen';
import AttendanceReportScreen from './screens/AttendanceReportScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MyTicketListScreen from './screens/MyTicketListScreen';
import MyTaskScreen from './screens/MyTaskScreen';
import WarehouseScreen from './screens/WarehouseScreen';
import WarehouseStockmoveScreen from './screens/WarehouseStockmoveScreen';
import PurchasingScreen from './screens/PurchasingScreen';
import PurchasingPurchaseListScreen from './screens/PurchasingPurchaseListScreen';
import MyFSM from './screens/FSMScreen';
import Employee from './screens/EmployeeScreen';

import WarehouseStockListScreen from './screens/WarehouseStockListScreen';
import { primaryColor } from './components/Constant';

const Stack = createNativeStackNavigator();


export default function App() {
  const [loader, setLoader] = useState(true);
  const [userToken, setUserToken] = useState(null);

  const authContext = useMemo(() => ({
    signIn: () => {
      setUserToken("dor");
      setLoader(false);
    },

    signOut: async () => {
      await AsyncStorage.removeItem('@login');
      setUserToken(null);
      setLoader(false);
    },
  
    signUp: () => {
      setLoader(false);
    },

    isSignIn: async () => {
      const loginx = await AsyncStorage.getItem("@login");
      if (loginx) {
        return "true";
      } else {
        return "false";
      }
    },

    dor: (string) => {
      var dor = string.replace('"','').replace('"','')
      return dor
    },

    getDayName: (dateStr, locale) => {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, { weekday: 'long' });
    },
  }));

  const checkAuth = async () => {
    try {

      const login = await AsyncStorage.getItem("@login");

      setUserToken(login)
      setLoader(false);
    } catch (error) {
      console.log("error check auth: "+ error)
    }
  }

  useEffect(() => {
      setTimeout( () => {
          setLoader(false);
      }, 1000);
      checkAuth();

      return () => {
        
      }

  }, [])

  if (loader) {
    return (
        <View style={{flex:1, justifyContent:'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color="#9370db" />
        </View>
    )
  }

  

  return (
    <AuthContext.Provider value={authContext}>

        <NavigationContainer>
            {
                userToken == null ? 
                  <Stack.Navigator >
                    <Stack.Screen options={{headerShown: false}} name="Home" component={HomeScreen} />
                    <Stack.Screen options={{headerShown: true, title: "Forgot Password"}} name="ForgotPassword" component={ForgotPasswordScreen} />
                  </Stack.Navigator>
                :
                  <Stack.Navigator screenOptions={{
                    headerStyle: {
                      backgroundColor: primaryColor,
                    },
                    headerTintColor: '#fff',
                  }} >
                      <Stack.Screen options={{headerShown: false}} name="Homepage" component={HomepageScreen} userToken={userToken} />
                      <Stack.Screen options={{headerShown: true, title: "Profile"}} name="Profile" component={ProfileScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Edit"}} name="ProfileEdit" component={ProfileEditScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Attendance"}} name="Attendance" component={AttendanceScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Report Attendance"}} name="AttendanceReport" component={AttendanceReportScreen} />
                      <Stack.Screen options={{headerShown: true, title: "News"}} name="News" component={NewsScreens} />
                      <Stack.Screen options={{headerShown: true, title: 'My Ticket List'}} name="MyTicketList" component={MyTicketListScreen} />
                      <Stack.Screen options={{headerShown: true, title: "My Task" }} name="MyTask" component={MyTaskScreen} />
                      <Stack.Screen options={{headerShown: true, title: "My FSM" }} name="MyFSM" component={MyFSM} />
                      <Stack.Screen options={{headerShown: true, title: "Employees" }} name="Employee" component={Employee} />
                      <Stack.Screen options={{headerShown: true, title: "Services" }} name="Services" component={ServiceScreen} />

                      <Stack.Screen options={{headerShown: true, title: "Warehouse" }} name="Warehouse" component={WarehouseScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Stockmove" }} name="Warehouse/Stockmove" component={WarehouseStockmoveScreen} />
                      <Stack.Screen options={{headerShown: true, title: "StockList" }} name="Warehouse/StockList" component={WarehouseStockListScreen} />
                      {/* END WAREHOUSE */}
                      {/* PURCHASING */}
                      <Stack.Screen options={{headerShown: true, title: "Purchasing" }} name="Purchasing" component={PurchasingScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Purchase List" }} name="Purchasing/PurchaseList" component={PurchasingPurchaseListScreen} />
                      <Stack.Screen options={{headerShown: true, title: "Discuss"}} name="Discuss" component={DiscussScreen} />
                  </Stack.Navigator>
              }
        </NavigationContainer>

    </AuthContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
