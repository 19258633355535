import { Button, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { primaryColor } from '../components/Constant'

const PurchasingScreen = ({route, navigation}) => {
  return (
    <SafeAreaView>
        <ScrollView>
            <View style={styles.container}>
                <View style={styles.buttonView}>
                    <Button title="Purchase List" color={primaryColor} onPress={ () => navigation.navigate('Purchasing/PurchaseList',{
                        login: route.params.login,
                        password: route.params.password
                    }) }></Button>
                </View>
            </View>
        </ScrollView>
    </SafeAreaView>
  )
}

export default PurchasingScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonView: {
        marginTop: 20,
        marginBottom: 20,
        width: '50%',
        backgroundColor: "#9370db",
        borderRadius: 30,
        overflow: "hidden",
      }
})