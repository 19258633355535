import { Platform } from 'react-native'

export const MAPI = "http://mapi.fibermedia.co.id:21491";
export const primaryColor = "#9370db";
export const CONSTANT_NUMBER_1 = 'hello I am a constant';
export const CONSTANT_NUMBER_2 = 'hello I am also a constant';
export const globalStyles = {
    osContainer: {
        width: (Platform.OS == 'web'? 425 : '100%'),
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'center'
    },
}