import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const CardNews = (props) => {
  const regex = /(<([^>]+)>)/ig;
  const caption = props.caption.replace(regex, '');
  return (
    <View style={styles.container}>
      <View style={styles.newsTitleView}>
        <Text style={styles.newsTitleText}>{props.title}</Text>
      </View>
      <View style={styles.newsContentView}>
        <Text style={styles.newsContentText}>{caption}</Text>
      </View>
      <View style={styles.newsFooterView}>
        <Text style={styles.newsFooterText}>Created at {props.create_date}</Text>
      </View>
    </View>
  )
}

export default CardNews

const styles = StyleSheet.create({
  container: {
      flexBasis: 75,
      borderWidth: 2,
      borderColor: '#9370db',
      borderRadius: 20,
      height: 100,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
  },
  newsTitleView: {
    flex: 1,
    marginBottom: 10,
  },
  newsTitleText: {
    height: 50,
    fontSize: 18,
    color: "#9370db"
  },
  newsContentView: {
    flex: 1,
    marginBottom: 10,
  },
  newsContentText: {
    fontSize: 14,
  },
  newsFooterView: {
    flex: 0,
  },
  newsFooterText: {
    fontSize: 12,
  }
})