import { Button, Image, SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import SayaLoader from '../components/SayaLoader';
import axios from 'axios';
import { useIsFocused } from '@react-navigation/native';
import { globalStyles, MAPI } from '../components/Constant';

const ProfileScreen = ({route, navigation}) => {
  const [loader, setLoader] = useState(true);
  const [IdentificationId, setIdentificationId] = useState([]);
  const [Birthday, setBirthday] = useState([]);
  const [MobilePhone, setMobilePhone] = useState([]);
  const [WorkMobile, setWorkMobile] = useState([]);
  const [WorkPhone, setWorkPhone] = useState([]);
  const [WorkEmail, setWorkEmail] = useState([]);
  const [WorkAddress, setWorkAddress] = useState([]);
  const [WorkLocation, setWorkLocation] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [JobTitle, setJobTitle] = useState([]);
  const [Address, setAddress] = useState([]);
  const [Nationality, setNationality] = useState([]);
  const [Gender, setGender] = useState([]);
  const [Dob, setDob] = useState([]);
  const [PlaceOfBirth, setPlaceOfBirth] = useState([]);
  const [CountryOfBirth, setCountryOfBirth] = useState([]);
  const [IdentificationNo, setIdentificationNo] = useState([]);
  const [Marital, setMarital] = useState([]);
  const [NumberOfChildren, setNumberOfChildren] = useState([]);
  const [EmergencyContact, setEmergencyContact] = useState([]);
  const [EmergencyPhone, setEmergencyPhone] = useState([]);
  const [Certificate, setCertificate] = useState([]);
  const [CertificateLevel, setCertificateLevel] = useState([]);
  const [Other, setOther] = useState([]);
  const [FieldOfStudy, setFieldOfStudy]     = useState([]);
  const [School, setSchool]     = useState([]);

  // IMAGES
  const [image_256, setimage_256] = useState([]);

  const { login, password, name, companyName } = route.params;
  const isFocused = useIsFocused();

  useEffect( () => {
    myEmployee()
  }, [isFocused])


  // INIT
  const myEmployee = () => {
    setLoader(true);

    axios({
      method: 'post',
      url: MAPI+'/myemployee',
      headers: {
        "Content-Type": "application/json", 
      },
      data: {
        email: login,
        password: password,
      }
    })
    .then(function (response) {

      if (response.data.code == "200") {
          setMobilePhone((response.data.data[0].mobile_phone?response.data.data[0].mobile_phone:''))
          setWorkPhone(response.data.data[0].work_phone)
          setWorkEmail(response.data.data[0].work_email)
          setWorkLocation(response.data.data[0].work_location.toUpperCase())
          setDepartment((response.data.data[0].department_id?response.data.data[0].department_id[1].split("/").pop(): ''))
          setJobTitle(response.data.data[0].job_title.toUpperCase())
          setGender( (response.data.data[0].gender? response.data.data[0].gender.toUpperCase() : ''))
          setBirthday(response.data.data[0].birthday)
          setMarital(response.data.data[0].marital.toUpperCase())
          setPlaceOfBirth(( response.data.data[0].place_of_birth? response.data.data[0].place_of_birth.toUpperCase() : '') )
          setEmergencyContact((response.data.data[0].emergency_contact? response.data.data[0].emergency_contact.toUpperCase() : '') )
          setEmergencyPhone(response.data.data[0].emergency_phone)
          setCountryOfBirth(response.data.data[0].country_of_birth)
          setCertificate(response.data.data[0].certificate)
          setFieldOfStudy(( response.data.data[0].study_field? response.data.data[0].study_field.toUpperCase() : '') )
          setSchool((response.data.data[0].study_school? response.data.data[0].study_school.toUpperCase() : '') )
          setimage_256((response.data.data[0].image_256?response.data.data[0].image_256:''))

          setLoader(false)
      } else {
        setLoader(false)
        alert(response.data.message)
      }
    })
    .catch(function (error) {
      alert("Error fetch profile")
      setLoader(false)
      console.log(error);
    });
  }

  if ( loader ) {
    return (
      <SayaLoader />
    )
  }

  return (
    <View style={globalStyles.osContainer}>
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView style={styles.scrollView}>
          <View style={styles.container}>
            <View style={styles.jumbotronView}>
              <View style={{flex: 1, alignItems:'center', marginTop: 15,}}>
                  <Image
                    style={styles.profileImgHeader}
                    source={{
                      uri: `data:image/png;base64,${image_256}`
                    }}
                  />
                  <Text style={styles.textName}>{name}</Text>
                  <Text style={styles.textCompany}>{login}</Text>
                  <Text style={styles.textCompany}>{companyName}</Text>

                  <View style={styles.containerRow}>
                    <View >
                      <Text style={{ textAlign: "center", color: "#fff" }}>Department</Text>
                      <Text style={{ textAlign: "center", color: "#fff", fontWeight: 'bold' }}>{Department}</Text>
                    </View>
                  </View>

                  <View style={styles.containerRow}>
                    <View >
                      <Text style={{ textAlign: "center", color: "#fff" }}>Job Title</Text>
                      <Text style={{ textAlign: "center", color: "#fff", fontWeight: 'bold' }}>{JobTitle}</Text>
                    </View>
                  </View>
                  
              </View>

            </View>
          </View>

          <View style={styles.containerData}>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Mobile Phone
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {MobilePhone}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Work Email
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {WorkEmail}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Work Phone
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {WorkPhone}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Work Location
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {WorkLocation}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Gender
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {Gender}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Birthday
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {Birthday}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Place Of Birth
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {PlaceOfBirth}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Country Of Birth
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {CountryOfBirth[1]}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Study Field
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {FieldOfStudy}
              </Text>
            </View>
            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Study School
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {School}
              </Text>
            </View>

            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Emergency Contact
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {EmergencyContact}
              </Text>
            </View>
            <View style={{ marginBottom: 10}}>
              <Text style={{textAlign: 'auto'}}>
                Emergency Phone
              </Text>
              <Text style={{textAlign: 'auto', fontWeight: 'bold'}}>
                {EmergencyPhone}
              </Text>
            </View>
          </View>
        </ScrollView>
          <View style={styles.buttonEditView}>
            <Button title='Edit' onPress={ () => { navigation.navigate("ProfileEdit", {
              login: login,
              password: password,
              name: name,
              Gender: Gender,
              MobilePhone: MobilePhone,
              WorkMobile: WorkMobile,
              WorkPhone: WorkPhone,
              WorkEmail: WorkEmail,
              WorkLocation: WorkLocation,
              Birthday: Birthday,
              PlaceOfBirth: PlaceOfBirth,
              CountryOfBirth: CountryOfBirth,
              FieldOfStudy: FieldOfStudy,
              School: School,
              EmergencyContact: EmergencyContact,
              EmergencyPhone: EmergencyPhone,
              Marital: Marital,
              image_256: `data:image/png;base64,${image_256}`,
            }) } } color={"#9370db"} style={styles} />
          </View>
      </SafeAreaView>
    </View>
  )
}

export default ProfileScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  safeAreaView: {
    flex: 1,
  },
  scrollView: {
    // backgroundColor: 'pink',
    marginHorizontal: 0,
  },
  jumbotronView: {
      marginTop: 16,
      width: "95%",
      height: 350,
      backgroundColor: '#9370db',
      borderRadius: 30,
  },
  profileImgHeader: {
      width: 120,
      height: 120,
      borderRadius: 60,
  },
  textName: {
    marginTop: 10,
    color: "#fff",
    fontSize: 16,
    fontWeight: 'bold',
  },
  textCompany: {
    marginTop: 10,
    color: "#fff",
    fontSize: 12,
  },

  containerRow: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: 16,
  },
  containerCol: {
    flexBasis: 100,
    margin: 5,
  },
  containerData: {
    flex: 1,
    padding: 16,
  },

  buttonEditView: {
      overflow: "hidden",
  }
})